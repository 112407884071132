
import Vue from 'vue';
import {Component} from 'vue-property-decorator';

@Component
class ContactForm extends Vue {
  formData = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    termin: [],
    message: '',
  };

  get isFormInvalid() {
    const requiredFieldsKeys = ['firstName', 'lastName', 'email', 'message'];

    return requiredFieldsKeys.some(key => !this.formData[key]);
  }

}

export default ContactForm;
