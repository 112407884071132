
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
class Footer extends Vue {
  get navigationItems() {
    return [
      {
        name: 'Drugi pogled',
        link: '/drugiPogled'
      },
      {
        name: 'O meni',
        link: '/oMeni'
      },
      {
        name: 'O Gestalt terapiji',
        link: '/oGestaltTerapiji'
      },
      {
        name: 'Usluge i cjenik',
        link: '/usluge'
      },
      {
        name: 'Suradnja',
        link: '/suradnja'
      },
      {
        name: 'Kontakt',
        link: '/kontakt'
      },
    ];
  }
}

export default Footer;
