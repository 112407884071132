
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
class Features extends Vue {
  get features() {
    return [
      {
        name: 'Individualno <span class="is-block-desktop">savjetovanje</span>',
        icon: require('@/assets/images/features/individulano_savjetovanje.png'),
      },
      {
        name: 'Partnersko <span class="is-block-desktop">savjetovanje</span>',
        icon: require('@/assets/images/features/partnersko_savjetovanje.png'),
      },
      {
        name: '<span class="is-block-desktop"> Savjetovanje </span> za mlade',
        icon: require('@/assets/images/features/savjetovanje_za_mlade.png'),
      },
    ];
  }
}

export default Features;
